import { default as _91event_id_93QXQaSbdiWLMeta } from "/tmp/build_47b5bfc6/pages/events/[event_id].vue?macro=true";
import { default as experiencesLwMN4kzZRcMeta } from "/tmp/build_47b5bfc6/pages/experiences.vue?macro=true";
import { default as indexLbVARlHBJTMeta } from "/tmp/build_47b5bfc6/pages/index.vue?macro=true";
import { default as _91meeting_type_id_93Qj8zqWQbIzMeta } from "/tmp/build_47b5bfc6/pages/sessions/[meeting_type_id].vue?macro=true";
import { default as index4VonLnXvPnMeta } from "/tmp/build_47b5bfc6/pages/sessions/index.vue?macro=true";
import { default as events_45slider_45exampledoBih98HK3Meta } from "/tmp/build_47b5bfc6/pages/widgets/events-slider-example.vue?macro=true";
import { default as events_45sliderS3RkvlA9iXMeta } from "/tmp/build_47b5bfc6/pages/widgets/events-slider.vue?macro=true";
export default [
  {
    name: "events-event_id",
    path: "/events/:event_id()",
    component: () => import("/tmp/build_47b5bfc6/pages/events/[event_id].vue")
  },
  {
    name: "experiences",
    path: "/experiences",
    meta: experiencesLwMN4kzZRcMeta || {},
    component: () => import("/tmp/build_47b5bfc6/pages/experiences.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/tmp/build_47b5bfc6/pages/index.vue")
  },
  {
    name: "sessions-meeting_type_id",
    path: "/sessions/:meeting_type_id()",
    component: () => import("/tmp/build_47b5bfc6/pages/sessions/[meeting_type_id].vue")
  },
  {
    name: "sessions",
    path: "/sessions",
    meta: index4VonLnXvPnMeta || {},
    component: () => import("/tmp/build_47b5bfc6/pages/sessions/index.vue")
  },
  {
    name: "widgets-events-slider-example",
    path: "/widgets/events-slider-example",
    meta: events_45slider_45exampledoBih98HK3Meta || {},
    component: () => import("/tmp/build_47b5bfc6/pages/widgets/events-slider-example.vue")
  },
  {
    name: "widget-events-slider",
    path: "/widgets/events-slider",
    meta: events_45sliderS3RkvlA9iXMeta || {},
    component: () => import("/tmp/build_47b5bfc6/pages/widgets/events-slider.vue")
  }
]